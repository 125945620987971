<template>
    <div>
        <ul class="nav nav-tabs"
            id="myTab"
            role="tablist">
            <li v-for="(year, index) in this.getYears"
                class="nav-item"
                role="presentation">
                <button class="nav-link"
                    :class="index == 0 ? 'active': ''"
                    :id="getYearButtonId(year)"
                    data-toggle="tab"
                    :data-target="getTarget(year)"
                    type="button"
                    role="tab"
                    :aria-controls="year"
                    :aria-selected="index == 0 ? true : false">{{year}}</button>
            </li>

        </ul>

        <div class="tab-content"
            id="priceTabContent">
            <div v-for="(year, index) in this.getYears"
                class="tab-pane fade"
                :class="index == 0 ? 'show active' : ''"
                :id="getTabId(year)"
                role="tabpanel"
                :aria-labelledby="getTabAreaLabel(year)">
                <div class="price-hint">Der Preis kann bei Buchungen mit mehr als 2 Personen abweichen!</div>
                <table class="table pricelist table-hover">
                    <thead>
                        <tr class="subrow">
                            <th>Saison</th>
                            <th>Zeitraum</th>
                            <th class="text-center">Preis</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody v-for="sg in priceArray">
                        <tr v-for="price in sg.lines"
                            v-if="sg.service.type === 'RENT' && isDateInRange(price.fromdate, year)"
                            class="subrow">
                            <td class="subinfo td1">
                                <span>{{price.season}}</span>
                                <br/>
                            </td>
                            <td class="td2"><span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>&nbsp;</td>
                            <td class="text-center td3">
                                <span>{{getCalculatedPrice(price.value)}}</span>
                            </td>
                            <td class="td4">
                                <span v-if="sg.service.type === 'RENT' && sg.nightRange && sg.nightRange.min">
												{{sg.nightRange.min}} Nächte
  										  </span>
                            </td>
                            <td class="td5">
                                pro Nacht
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table pricelist table-hover">
                    <tbody v-for="sg in priceArray">
                        <tr class="mainrow"
                            v-if="sg.service.type !== 'RENT'">
                            <td class="td1">
                                {{sg.service.name}}
                                <small>
                             <span v-if="sg.nightRange"><span vo-msg="nights">#nights</span>: {{sg.nightRange.min}} - {{sg.nightRange.max}}</span>
                              <span v-if="sg.guestRange"><span vo-msg="guests">#guests</span>: {{sg.guestRange.min}} - {{sg.guestRange.max}}</span>
                           </small>
                            </td>
                            <td class="td2">&nbsp;</td>
                            <td class="text-center td3">{{getCalculatedPrice(sg.price)}}</td>
                            <td class="td4">&nbsp;</td>
                            <td class="td5">
                                <div v-if="sg.price && sg.service.calculation != 'FLAT'">
                                    {{sg.service.calculation}}
                                    <small v-if="sg.service.perAdult"> / <span vo-msg="adult">#adult</span></small>
                                    <small v-if="sg.service.perChild"> / <span vo-msg="child">#child</span></small>
                                </div>

                            </td>
                        </tr>
                        <tr v-for="price in sg.lines"
                            class="subrow"
                            v-if="sg.service.type !== 'RENT'">
                            <td class="subinfo td1">

                            </td>
                            <td class="td2">
                                <div v-if="price.season">
                                    <small>{{price.season}}</small></div>
                                <span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span></td>

                            <td class="text-center td3">
                                {{getCalculatedPrice(price.value)}}
                            </td>
                            <td class="td4">&nbsp;</td>
                            <td class="td5">
                                {{getCalculationName(sg.service.calculation)}}
                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-tab',
    	props: {
    		pricelist: String
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			unitId: Number,
    			priceBookingFee: Number,
    			priceFinalCleaning: Number
    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		this.priceArray = pricelist;

    		//console.log('Pricelist::', pricelist);
    		var years = [];

    		var i, j;
    		for (i = 0; i < pricelist.length; i++) {
    			if (pricelist[i].service.type && pricelist[i].service.type === 'FINALCLEANING') {
    				console.log('Type::', pricelist[i].service.type, 'Price::', pricelist[i].price);
    				this.priceFinalCleaning = pricelist[i].price;
    			}
    			if (pricelist[i].service.type && pricelist[i].service.type === 'BOOKING_FEE') {
    				console.log('Type::', pricelist[i].service.type, 'Price::', pricelist[i].price);
    				this.priceBookingFee = pricelist[i].price;
    			}
    			for (j = 0; j < pricelist[i].lines.length; j++) {
    				//console.log('inside loop date::' + pricelist[i].lines[j].fromdate);
    				if (pricelist[i].lines[j].fromdate) {
    					var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    					//console.log('year is::' + year);
    					if (years.indexOf(year) < 0) {
    						years.push(year);
    					}
    				}
    			}
    		}
    		//	console.log('years::' + years);
    		/*console.log('this.pricelist::' + this.pricelist); */
    	},
    	methods: {
    		getCalculationName: function(calcType) {
    			var str = '';
    			if (calcType === 'FLAT') {
    				str = 'pauschal';
    			} else if (calcType === 'NIGHT') {
    				str = 'pro Nacht';
    			} else if (calcType === 'DAY') {
    				str = 'pro Tag';
    			} else if (calcType === 'WEEK') {
    				str = 'pro Woche';
    			} else if (calcType === 'MONTH') {
    				str = 'pro Monat';
    			} else if (calcType === 'USAGE') {
    				str = 'nach Verbrauch';
    			}

    			return str;

    		},
    		getYearButtonId: function(year) {
    			return 't' + year + '-tab';
    		},
    		getTarget: function(year) {
    			return "#t" + year;
    		},
    		getTabId: function(year) {
    			return "t" + year;
    		},
    		getTabAreaLabel: function(year) {
    			return "t" + year + "-tab";
    		},
    		getFormattedDate: function(date) {
    			return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    		},
    		getCalculatedPrice: function(price) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			if (price > 0) {
    				rentPrice = price;
    				calcPrice = Number(rentPrice / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    				return calcPrice;
    			}
    		},
    		isDateInRange: function(fromdate, year) {
    			if (!fromdate) {
    				return true;
    			}
    			var fromYear = parseInt(fromdate.substring(0, 4));
    			if (fromYear == year) {
    				return true;
    			}
    			return false;
    		}

    	},
    	computed: {
    		getYears: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			console.log('Pricelist::', pricelist);
    			var years = [];

    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				for (j = 0; j < pricelist[i].lines.length; j++) {
    					if (pricelist[i].lines[j].fromdate) {
    						var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    						if (years.indexOf(year) < 0) {
    							years.push(year);
    						}
    					}
    				}
    			}
    			return years;
    		}
    	}
    };
</script>